import React, { Component } from 'react'
import Cookie from 'js-cookie'
import checkSocialDomains from '../utils/checkSocialDomains'
import analytics from '../components/analytics/Analytics'
import masterAnalytics, { handleAnalyticsEvent } from '../components/analytics/MasterAnalytics'

import { getSessionStorageItem, setSessionStorageItem } from '../utils/sessionStorage'

export const BenderAppContext = React.createContext()
export const BenderAppConsumer = BenderAppContext.Consumer

const SEARCH_ENGINE_HOSTNAMES = ['google', 'duckduckgo', 'bing', 'yahoo', 'baidu', 'yandex', 'ya']

export const handleGaEvent = (category, action, label, value = '') => {
  if (!category || !action) return
  const gaLabel = label || ''
  analytics &&
    analytics.track(action, {
      category,
      label,
      value
    })

  if (window.gtag && category && action) {
    window.gtag('event', action, {
      event_category: category,
      event_label: gaLabel
    })
  }

  handleAnalyticsEvent(action, category, label)
}

class BenderAppProvider extends Component {
  state = {
    loadedApp: false,
    headerLoaded: false,
    userAgent: ''
  }

  componentDidMount() {
    this.loadAppData()

    if (
      !this.getAllUrlParams() &&
      !this.getSessionStorageParams() &&
      !this.getDocReferrerParams() &&
      !this.attributionParams()
    ) {
      const utmMedium = 'Unknown'
      const utmSource = 'Unknown'
      const utmCampaign =
        (typeof document !== 'undefined' &&
          document.referrer &&
          new URL(document.referrer).hostname) ||
        ''

      setSessionStorageItem('utm_medium', utmMedium)
      setSessionStorageItem('utm_source', utmSource)
      setSessionStorageItem('utm_campaign', utmCampaign)

      const docRefObj = {
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_campaign: utmCampaign
      }

      this.setState({
        urlParameters: docRefObj,
        ...docRefObj
      })
    }
    this.startTracking()
  }

  startTracking = () => {
    const cookieOptIn = Cookie.get('optedIn') || ''
    const gaClientId = Cookie.get('_ga') || ''

    const { gaTrackingId, userAgent, urlParameters } = this.state

    analytics.page({
      utmCampaign: (urlParameters && urlParameters.utmCampaign) || 'none',
      utmContent: (urlParameters && urlParameters.utmContent) || 'none',
      utmMedium: (urlParameters && urlParameters.utmMedium) || 'none',
      utmSource: (urlParameters && urlParameters.utmSource) || 'none',
      utmTerm: (urlParameters && urlParameters.utmTerm) || 'none',
      gaTrackingId: (gaTrackingId && gaTrackingId) || 'none',
      userAgent: (userAgent && userAgent) || 'none',
      cookieOptIn,
      gaClientId
    })

    masterAnalytics.page()
  }

  getDocReferrerParams = () => {
    const docReferrer = document.referrer && new URL(document.referrer)
    if (docReferrer && docReferrer.hostname) {
      const match = SEARCH_ENGINE_HOSTNAMES.find(se => {
        const sld = docReferrer.hostname.split('.').reverse()[1]
        return sld && sld === se
      })
  
      if (match) {
        const utmMedium = 'Organic'
        const utmSource = docReferrer.hostname

        const docRefObj = {
          utm_medium: utmMedium,
          utm_source: utmSource
        }
        setSessionStorageItem('utm_medium', utmMedium)
        setSessionStorageItem('utm_source', utmSource)
        this.setState({
          urlParameters: docRefObj,
          ...docRefObj
        })
        return true
      }
    }
    return false
  }

  attributionParams = () => {
    const fallbackSocialDomains = checkSocialDomains()
    if (Object.keys(fallbackSocialDomains).length > 0) {
      return true
    }
    return false
  }

  getSessionStorageParams = () => {
    const utmObj = {}
    const utmCampaign = getSessionStorageItem('utm_campaign')
    if (utmCampaign) utmObj.utm_campaign = utmCampaign
    const utmSource = getSessionStorageItem('utm_source')
    if (utmSource) utmObj.utm_source = utmSource
    const utmMedium = getSessionStorageItem('utm_medium')
    if (utmMedium) utmObj.utm_medium = utmMedium
    const utmTerm = getSessionStorageItem('utm_term')
    if (utmTerm) utmObj.utm_term = utmTerm
    const utmContent = getSessionStorageItem('utm_content')
    if (utmContent) utmObj.utm_content = utmContent

    this.setState({
      urlParameters: { ...utmObj },
      ...utmObj
    })
    if (Object.keys(utmObj).length > 0) return true
    return false
  }

  getAllUrlParams = url => {
    // get query string from url (optional) or window
    const queryString = url ? url.split('?')[1] : window.location.search.slice(1)

    // we'll store the parameters here
    const obj = {}

    // if query string exists
    if (queryString) {
      // stuff after # is not part of query string, so get rid of it
      const [queries] = queryString.split('#')

      // split our query string into its component parts
      const arr = queries.split('&')

      for (let i = 0; i < arr.length; i += 1) {
        // separate the keys and the values
        const a = arr[i].split('=')

        // in case params look like: list[]=thing1&list[]=thing2
        const paramNum = undefined
        let paramName = a[0].replace(/\[\d*\]/)

        // set parameter value (use 'true' if empty)
        let paramValue = typeof a[1] === 'undefined' ? false : a[1]

        // (optional) keep case consistent
        paramName = paramName.toLowerCase()
        paramValue = paramValue || ''

        // if parameter name already exists
        if (obj[paramName]) {
          // convert value to array (if still string)
          if (typeof obj[paramName] === 'string') {
            obj[paramName] = [obj[paramName]]
          }
          // if no array index number specified...
          if (typeof paramNum === 'undefined') {
            // put the value on the end of the array
            obj[paramName].push(paramValue)
          }
          // if array index number specified...
          else {
            // put the value at that index number
            obj[paramName][paramNum] = paramValue
          }
        }
        // if param name doesn't exist yet, set it
        else {
          obj[paramName] = paramValue
        }
        setSessionStorageItem(paramName, paramValue)
        this.setState({ urlParameters: obj, [paramName]: paramValue })
      }
    }
    if (Object.keys(obj).length > 0) return true
    return false
  }

  loadAppData = () => {
    let url
    this.pagePath = window.location.pathname
    this.pagePath = this.pagePath.replace(/\//g, '+')

    // Check if localhost, mamp server, staging or live and set different page urls
    if (
      window.location.toString().indexOf('localhost') !== -1 ||
      window.location.toString().indexOf('newsummit') !== -1
    ) {
      url = `http://newsummit.loc/wp-json/web/main-data/${this.pagePath}`
    } else {
      url = `/wp-json/web/main-data/${this.pagePath}`
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        const slugifiedPath = this.slugify(window.location.pathname)
        const pageData = {
          loadedApp: true,
          slugifiedPath,
          ...data
        }

        this.setState(pageData)
      })
  }

  slugify = string => {
    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^a-zA-Z ]/g, '_') // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

  headerOverwrites = headerPageOverwrites => {
    if (!this.state.headerLoaded) {
      this.setState(prevState => ({
        header: {
          ...prevState.header,
          page_overwrites: headerPageOverwrites
        },
        headerLoaded: true
      }))
    }
  }

  render() {
    return (
      <BenderAppContext.Provider
        value={{
          ...this.state,
          header_overwrites: this.headerOverwrites,
          slugify: this.slugify,
          getAllUrlParams: this.getAllUrlParams,
          handleGaEvent,
          handleAnalyticsEvent,
          startTracking: this.startTracking
        }}
      >
        {this.props.children}
      </BenderAppContext.Provider>
    )
  }
}
export default BenderAppProvider
