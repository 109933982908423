import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if(typeof(window.scroll)!="undefined") {
                window.scroll({
                    top: 0, 
                    left: 0, 
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);