import { setSessionStorageItem } from './sessionStorage'

const SOURCE_DOMAINS = [
  {
    label: 'Facebook',
    links: ['facebook.com', 'fb.me']
  },
  {
    label: 'Instagram',
    links: ['instagram.com']
  },
  {
    label: 'LinkedIn',
    links: ['linkedin.com', 'lnkd.in', 'linkedin.android']
  },
  {
    label: 'Pintrest',
    links: ['pinterest']
  },
  {
    label: 'Reddit',
    links: ['reddit.com']
  },
  {
    label: 'Tiktok',
    links: [
      'tiktok.com',
      'tiktokcdn-in.com',
      'tiktokcdn.com',
      'tiktokv.com',
      'ttlivecdn.com',
      'musical.ly'
    ]
  },
  {
    label: 'Twitter',
    links: ['twitter.com']
  },
  {
    label: 'GitHub',
    links: ['github.com']
  },
  {
    label: 'YouTube',
    links: ['youtube.com', 'youtu.be']
  },
  {
    label: 'Vimeo',
    links: ['vimeo.com']
  }
]

const checkSocialDomains = () => {
  if (!navigator || !navigator.cookieEnabled) return {}
  const docReferrer =
    typeof document !== 'undefined' && document.referrer && new URL(document.referrer)

  if (!('utm_campaign' in sessionStorage)) {
    if (docReferrer && docReferrer.hostname) {
      const match = SOURCE_DOMAINS.find(item => {
        return item.links.find(link => {
          const domainPartsReversed = docReferrer.hostname.split('.').reverse()
          const sldAndFld = `${domainPartsReversed[1]}.${domainPartsReversed[0]}`
          return link === sldAndFld
        })
      })

      if (match) {
        const utmMedium = 'Organic'
        const utmSource = 'Social'
        const utmCampaign = match.label
        setSessionStorageItem('utm_medium', utmMedium)
        setSessionStorageItem('utm_source', utmSource)
        setSessionStorageItem('utm_campaign', utmCampaign)

        const docRefObj = {
          utm_medium: utmMedium,
          utm_source: utmSource,
          utm_campaign: utmCampaign
        }

        if (this !== undefined) {
          this.setState({
            urlParameters: docRefObj,
            ...docRefObj
          })
        }

        return docRefObj
      }
    }
  }
  return {}
}

export default checkSocialDomains
