/* eslint-disable */
export const _paq = (window._paq = window._paq || [])

export default function matomoPlugin(config) {
  return {
    name: 'matomo',
    initialize: () => {
      if (config.enabled) {
        _paq.push(['enableLinkTracking'])
        _paq.push(['enableHeartBeatTimer'])
        ;(function() {
          const u = config.url
          _paq.push(['setTrackerUrl', `${u}matomo.php`])
          _paq.push(['setSiteId', config.siteId])
          _paq.push(['requireCookieConsent'])
          const d = document

          const g = d.createElement('script')

          const s = d.getElementsByTagName('script')[0]
          g.type = 'text/javascript'
          g.async = true
          g.id = 'matomo-script'
          g.src = `${u}matomo.js`
          s.parentNode.insertBefore(g, s)
        })()
      }
    },
    page: () => {
      _paq.push(['trackPageView'])
    },
    track: ({
      payload: {
        properties: { action, category, label }
      }
    }) => {
      window._paq.push(['trackEvent', action, category, label])
    }
  }
}
