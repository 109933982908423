import React, { Component } from 'react'

// Set Up The Initial Context
export const BenderPagesContext = React.createContext()
// Create an exportable consumer that can be injected into components
export const BenderPagesConsumer = BenderPagesContext.Consumer
// Create the provider using a traditional React.Component class
class BenderPagesProvider extends Component {

    state = {
        pagesLoaded: [],
        pagesLoadedContent: {},
        latestPosts: [],
        featuredPosts: [],
        currentPage: '',
        loadedPages: true,
        loadingMorePosts: false,
        featuredAuthors: '',
        currentPageNum: 0,
        maxPageNum: 1,
        currentCategPageNum: 0,
        currentAuthPageNum: 0,
        morePosts: true
    }

    componentDidMount() {
        // Get pagePath
        let pagePath = window.location.pathname
        if (pagePath !== '/') {
            pagePath = pagePath.replace('/', '');
        } else {
            // if landing page replace / with + to help wp API handle the request
            pagePath = '+';
        }
        // if not the currentPage path
        if (this.state.currentPage !== pagePath) {
            // load current page
            this.loadCurrentPage(pagePath)
        }
    }
    componentDidUpdate() {
        // if component updated
        // get current pagePath
        let pagePath = window.location.pathname;
        if (pagePath !== '/') {
            pagePath = pagePath.replace('/', '');
        } else {
            pagePath = '+';
        }
        // if not current page load data
        if (this.state.currentPage !== pagePath) {
            this.loadCurrentPage(pagePath)
        }
    }

    loadCurrentPage = pagePath => {
        // load current pagePath data
        if (this.state.pagesLoadedContent[pagePath]) {
            // if page already loaded just change currentPage path
            this.setState({
                currentPage: pagePath
            })
        } else if (pagePath.includes('author/')) {
            // get author by slug
            // move getAuthorData from author component
        } else if (pagePath.includes('category/')) {
            // if category getCategData
            this.getCategData(pagePath.replace('/category/', ''))
        } else {
            let { pagesLoaded, pagesLoadedContent, featuredAuthors, latestPosts, featuredPosts } = this.state,
                pageData = {
                    loadedPages: true,
                    currentPage: pagePath,
                    pagesLoaded,
                    latestPosts
                }

            let url;
            // Check if localhost, mamp server, staging or live and set different page urls
            if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

                url = `http://newsummit.loc/wp-json/pages-list/all/${pagePath}`

            } else {

                url = `/wp-json/pages-list/all/${pagePath}`

            }

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    let pageContent = data
                    if (pagePath === 'blog' || pagePath === 'blog/') {
                        // if blog home page
                        let postsContent = data.featured_posts.concat(data.latest_posts),
                            featuredAuthorsData = data.featured_authors,
                            featuredAuthorsFix = []

                        let featuredAuthorsDataFix = this.fixFeaturedAuthorsData(featuredAuthorsData, pagesLoaded, pagesLoadedContent, featuredAuthorsFix)

                        for (let index = 0; index < postsContent.length; index++) {
                            const element = postsContent[index];
                            pagesLoaded.push(element.slug);
                            pagesLoadedContent[element.slug] = element;
                        }

                        for (let index = 0; index < data.latest_posts.length; index++) {
                            const element = data.latest_posts[index];
                            latestPosts.indexOf(element.slug) === -1 && latestPosts.push(element.slug)
                        }
                        for (let index = 0; index < data.featured_posts.length; index++) {
                            const element = data.featured_posts[index];
                            featuredPosts.indexOf(element.slug) === -1 && featuredPosts.push(element.slug);
                        }

                        pageData['featuredAuthors'] = featuredAuthorsDataFix.featuredAuthorsFix

                        pageData['maxPageNum'] = Math.ceil((data.latest_posts_pages * 4) / 24)

                        pagesLoaded.push(pagePath);
                        pagesLoadedContent[pagePath] = pageContent;

                        this.setState(pageData)


                    } else if (pagePath.includes('blog/') && (pagePath !== 'blog')) {
                        // if single blog posts
                        let featuredAuthorsData = pageContent.featured_authors,
                            featuredAuthorsFix = [],
                            relatedPosts = pageContent.related_posts

                        pageContent['post_liked'] = false

                        // get featured authors post and add them to the list of pages loaded
                        featuredAuthorsData && this.fixFeaturedAuthorsData(featuredAuthorsData, pagesLoaded, pagesLoadedContent, featuredAuthorsFix)
                        featuredAuthors = featuredAuthorsFix
                        pageData['featuredAuthors'] = featuredAuthors

                        // Related posts loop
                        for (let index = 0; index < relatedPosts.length; index++) {
                            const post = relatedPosts[index];
                            pagesLoaded.push(post.slug);
                            pagesLoadedContent[post.slug] = post;
                        }

                        // push to pages loaded slugs
                        pagesLoaded.push(pagePath);
                        // push to pages loaded content
                        pagesLoadedContent[pagePath] = pageContent;

                        this.setState(pageData)

                    } else {
                        // any other page
                        pagesLoaded.push(pagePath);
                        pagesLoadedContent[pagePath] = pageContent;

                        this.setState(pageData)
                    }

                })
        }

    }

    getAuthorData = authorId => {
        let pagePath = window.location.pathname
        pagePath = pagePath.replace('/', '');

        if (!this.state.pagesLoadedContent[pagePath]) {
            let { pagesLoaded, pagesLoadedContent, currentAuthPageNum } = this.state,
                pageData = {
                    loadedPages: true,
                    currentPage: pagePath,
                    currentAuthPageNum: currentAuthPageNum + 1,
                    pagesLoaded,
                    pagesLoadedContent,
                }

            let url;
            // Check if localhost, mamp server, staging or live and set different page urls
            if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

                url = `http://newsummit.loc/wp-json/author/${authorId}/0`

            } else {

                url = `/wp-json/author/${authorId}/0`

            }
            fetch(url)
                .then(response => response.json())
                .then(data => {

                    let postsContent = data.posts,
                        postsSlugArray = []

                    if (postsContent) {
                        for (let index = 0; index < postsContent.length; index++) {
                            const element = postsContent[index];

                            pagesLoaded.push(element.slug);
                            if (!(element.slug in pagesLoadedContent)) {
                                pagesLoadedContent[element.slug] = element;
                            }

                            postsSlugArray.push(element.slug)
                        }

                        data["posts"] = postsSlugArray
                    }

                    pageData.morePosts = data.more_posts

                    pagesLoaded.push(pagePath);
                    pagesLoadedContent[pagePath] = data;

                    this.setState(pageData)
                })
        }
    }
    getMoreAuthorData = (authorId, pageNum) => {
        this.setState({ loadingMorePosts: true })

        let url;
        // Check if localhost, mamp server, staging or live and set different page urls
        if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

            url = `http://newsummit.loc/wp-json/author/${authorId}/${pageNum}`

        } else {

            url = `/wp-json/author/${authorId}/${pageNum}`

        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let { pagesLoaded, pagesLoadedContent, currentAuthPageNum } = this.state,
                    pageData = {
                        pagesLoaded,
                        pagesLoadedContent,
                        currentAuthPageNum: currentAuthPageNum + 1,
                        loadingMorePosts: false,
                        morePosts: data.more_posts,
                    },
                    authSlugFix = 'author/' + data.author_slug

                for (let index = 0; index < data.posts.length; index++) {
                    const element = data.posts[index];
                    if (!pagesLoaded.includes(element.slug)) {
                        pagesLoaded.push(element.slug);
                        pagesLoadedContent[element.slug] = element;
                        pagesLoadedContent[authSlugFix].posts.push(element.slug);
                    } else {
                        pagesLoadedContent[authSlugFix].posts.push(element.slug);
                    }
                }

                this.setState(pageData)
            })

    }

    getCategData = categSlug => {
        let pagePath = window.location.pathname;
        pagePath = pagePath.replace('/', '');

        let url;
        // Check if localhost, mamp server, staging or live and set different page urls
        if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

            url = `http://newsummit.loc/wp-json/category-posts/${categSlug}/0`

        } else {

            url = `/wp-json/category-posts/${categSlug}/0`

        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let { pagesLoaded, pagesLoadedContent, currentCategPageNum } = this.state,
                    pageData = {
                        loadedPages: true,
                        currentPage: pagePath,
                        currentCategPageNum: currentCategPageNum + 1,
                        morePosts: data.more_posts,
                        pagesLoaded,
                        pagesLoadedContent
                    },
                    postsContent = data.posts,
                    postsSlugArray = []

                for (let index = 0; index < postsContent.length; index++) {
                    const element = postsContent[index];

                    pagesLoaded.push(element.slug);
                    if (!(element.slug in pagesLoadedContent)) {
                        pagesLoadedContent[element.slug] = element;
                    }

                    postsSlugArray.push(element.slug)
                }

                data.posts = postsSlugArray

                pagesLoaded.push(pagePath);
                pagesLoadedContent[pagePath] = data;

                this.setState(pageData)
            })

    }
    getMoreCategData = (categSlug, pageNum) => {
        this.setState({ loadingMorePosts: true })

        let url;
        // Check if localhost, mamp server, staging or live and set different page urls
        if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

            url = `http://newsummit.loc/wp-json/category-posts/${categSlug}/${pageNum}`

        } else {

            url = `/wp-json/category-posts/${categSlug}/${pageNum}`

        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let { pagesLoaded, pagesLoadedContent, currentCategPageNum } = this.state,
                    pageData = {
                        pagesLoaded,
                        pagesLoadedContent,
                        currentCategPageNum: currentCategPageNum + 1,
                        loadingMorePosts: false,
                        morePosts: data.more_posts,
                    }

                for (let index = 0; index < data.posts.length; index++) {
                    const element = data.posts[index];
                    if (!pagesLoaded.includes(element.slug)) {
                        pagesLoaded.push(element.slug);

                        let categSlugFix = 'category/' + categSlug
                        pagesLoadedContent[categSlugFix].posts.push(element.slug);

                        pagesLoadedContent[element.slug] = element;
                    } else {
                        let categSlugFix = 'category/' + categSlug
                        pagesLoadedContent[categSlugFix].posts.push(element.slug);
                    }
                }

                this.setState(pageData)
            })

    }

    fixFeaturedAuthorsData = (featuredAuthorsData, pagesLoaded, pagesLoadedContent, featuredAuthorsFix) => {
        for (let index = 0; index < featuredAuthorsData.length; index++) {
            const author = featuredAuthorsData[index],
                authorPosts = author.posts,
                authorPostsArray = []

            if (authorPosts) {
                for (let index = 0; index < authorPosts.length; index++) {
                    const post = authorPosts[index];
                    pagesLoaded.push(post.slug);
                    pagesLoadedContent[post.slug] = post;

                    authorPostsArray.push(post.slug)
                }
                author.posts = authorPostsArray
            }

            featuredAuthorsFix.push(author)
        }
        return { featuredAuthorsFix, pagesLoaded, pagesLoadedContent }
    }

    updatePageLikes = postPath => {
        // console.log(postPath)
        let numOfLikes = this.state.pagesLoadedContent[postPath]['acf']['num_of_likes'],
            postId = this.state.pagesLoadedContent[postPath]['id'],
            numOfLikesUpdated = numOfLikes ? parseInt(numOfLikes) + 1 : 0 + 1

        // Update num of likes in pages state
        const newState = Object.assign({}, this.state);
        newState.pagesLoadedContent[postPath]['acf']['num_of_likes'] = numOfLikesUpdated;
        this.setState(newState);

        // update num of likes in wordpress
        let url;
        // Check if localhost, mamp server, staging or live and set different page urls
        if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

            url = `http://newsummit.loc/wp-json/pages/likes/${postId}`

        } else {

            url = `/wp-json/pages/likes/${postId}`

        }
        fetch(url)
    }

    addMorePages = page => {
        this.setState({ loadingMorePosts: true })

        let url;
        // Check if localhost, mamp server, staging or live and set different page urls
        if (window.location.toString().indexOf('localhost') !== -1 || window.location.toString().indexOf('newsummit') !== -1) {

            url = `http://newsummit.loc/wp-json/all-posts/${page}`

        } else {

            url = `/wp-json/all-posts/${page}`

        }
        fetch(url)
            .then(response => response.json())
            .then(data => {
                let { pagesLoaded, pagesLoadedContent, latestPosts, currentPageNum } = this.state,
                    pageData = {
                        pagesLoaded,
                        pagesLoadedContent,
                        currentPageNum: currentPageNum + 1,
                        loadingMorePosts: false
                    }

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if (!pagesLoaded.includes(element.slug)) {
                        pagesLoaded.push(element.slug);
                        pagesLoadedContent[element.slug] = element;
                    }
                    if (!latestPosts.includes(element.slug)) {
                        latestPosts.push(element.slug);
                    }
                }

                this.setState(pageData)
            })

    }

    render() {

        return (
            // value prop is where we define what values 
            // that are accessible to consumer components
            <BenderPagesContext.Provider
                value={{
                    pagesLoadedContent: this.state.pagesLoadedContent,
                    loadedPages: this.state.loadedPages,
                    currentPage: this.state.currentPage,
                    featuredAuthors: this.state.featuredAuthors,
                    latestPosts: this.state.latestPosts,
                    featuredPosts: this.state.featuredPosts,
                    currentPageNum: this.state.currentPageNum,
                    maxPageNum: this.state.maxPageNum,
                    currentCategPageNum: this.state.currentCategPageNum,
                    currentAuthPageNum: this.state.currentAuthPageNum,
                    loadingMorePosts: this.state.loadingMorePosts,
                    morePosts: this.state.morePosts,
                    updatePageLikes: this.updatePageLikes,
                    addMorePages: this.addMorePages,
                    getAuthorData: this.getAuthorData,
                    getMoreAuthorData: this.getMoreAuthorData,
                    getCategData: this.getCategData,
                    getMoreCategData: this.getMoreCategData,
                }}>
                {this.props.children}
            </BenderPagesContext.Provider>
        )
    }
}
export default BenderPagesProvider