import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './routes/helpers/ScrollToTopHelper'
import BenderAppProvider from './context/BenderAppContext'

import './index.scss'
import App from './components/app/App'

// import classList polyfil - test for empty submissions
require('./components/crossBrowserFix/classList')

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <BenderAppProvider>
        <App />
      </BenderAppProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
)
