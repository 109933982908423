export const setSessionStorageItem = (name, value) => {
  try {
    window.sessionStorage.setItem(name, value)
    return true
  } catch (e) {
    return false
  }
}

export const getSessionStorageItem = name => {
  try {
    const itemValue = window.sessionStorage.getItem(name)
    return itemValue
  } catch (e) {
    return false
  }
}
