import React, { Suspense, useState, useEffect, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'

// Context
import { BenderAppContext } from '../../context/BenderAppContext'
import BenderPagesProvider, { BenderPagesConsumer } from '../../context/BenderPagesContext'

// Components
import Loader from '../loader/Loader'

// Routes
const Bender = React.lazy(() => import('../../routes/Bender'))
const Blog = React.lazy(() => import('../../routes/Blog'))
const NotFound = React.lazy(() => import('../../routes/NotFound'))
const Essentials = React.lazy(() => import('../../routes/Essentials'))

// Components
const Header = React.lazy(() => import('../header/Header'))
const Footer = React.lazy(() => import('../footer/Footer'))
const Ticker = React.lazy(() => import('../ticker/Ticker'))
const SinglePost = React.lazy(() => import('../blog/SinglePost'))
const CivicCookie = React.lazy(() => import('../civicCookie/CivicCookie'))
const Author = React.lazy(() => import('../blog/Author'))
const Category = React.lazy(() => import('../blog/Category'))
const SinglePressRelease = React.lazy(() => import('../posts/postTypes/pressReleases/SinglePressRelease'))

export const scrollToElem = (element, scrollOffset = 0, elementType, topBottom, scrollType) => {
  // querySelector doesn't allow special charactes in selectors such as = and similar - that's why we have elementType check to get element by id
  const domElement =
    elementType === 'getById' ? document.getElementById(element) : document.querySelector(element)
  if (!domElement) return null
  const elementRect = domElement.getBoundingClientRect()
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  const scrollPositionTop = elementRect.top + scrollTop
  const scrollPositionBottom = elementRect.bottom + scrollTop
  let scrollPosition

  if (topBottom === 'bottom') {
    scrollPosition = scrollPositionBottom + scrollOffset
  } else {
    scrollPosition = scrollPositionTop + scrollOffset
  }

  if (scrollTop !== scrollPosition) {
    if (scrollType === 'snap') {
      window.scroll({
        top: scrollPosition
      })
    } else {
      window.scroll({
        top: scrollPosition,
        behavior: 'smooth'
      })
    }
  }
}

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth <= 1024)

  const appContext = useContext(BenderAppContext)

  const {
    loadedApp,
    conference_slug: conferenceSlug,
    conference_name: conferenceName,
    form,
    displayTicker,
    ticker,
    header,
    footer,
    cookie,
    pages,
    ads,
    header_overwrites: headerOverwrites,
    slugify,
    slugifiedPath,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_term: utmTerm,
    gclid,
    handleGaEvent,
    industry,
    urlParameters,
    stream_token
  } = appContext

  let displayTickerPopup = displayTicker
  if (!displayTickerPopup) {
    displayTickerPopup =
      header && header.page_overwrites && header.page_overwrites.display_page_countdown_ticker
  }

  const disableForms = form && form.disable_forms
  const disableFormsMessage = form && form.disable_forms_message

  const currentHostname = window.location.hostname

  // set primary color before anything is loaded
  let primaryColor = '#5d5d5d'
  switch (currentHostname) {
    case 'websummit':
      primaryColor = '#00ACA8'
      break
    case 'collisionconf':
      primaryColor = '#F26F21'
      break
    case 'riseconf':
      primaryColor = '#EE3D4C'
      break
    case 'moneyconf':
      primaryColor = '#026AC1'
      break
    default:
      break
  }

  useEffect(() => {
    // add event listener on resize to track if user resizes screen - to make sure some of our components give different layout on mobile
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      // make sure to remove the listener
      // when the component is not mounted anymore
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  })

  function handleWindowSizeChange() {
    const isDesktopWidth = window.innerWidth > 1024
    const isTabletWidth = window.innerWidth >= 768 && window.innerWidth <= 1024
    const isMobileWidth = window.innerWidth < 768

    // If mobile or tablet set state
    if (isMobileWidth && !isMobile) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (isTabletWidth && !isTablet) {
      setIsMobile(false)
      setIsTablet(true)
    } else if (isDesktopWidth && (isTablet || isMobile)) {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  // Create routes
  function buildRoutes() {
    const appPages = pages
    const currentPagePathName = window.location.pathname
    const currentPageHasTrailingSlash = /\/$/.test(currentPagePathName)

    // Loop through dynamic routes
    // set dedicated templates and paths
    const dynamicRoutesComponents =
      appPages &&
      appPages.map(item => {
        const itemPath = currentPageHasTrailingSlash ? `${item.path}/` : item.path
        // if template is post - single post pages
        if (item.template === 'Post') {
          return (
            <Route
              exact
              path={itemPath}
              key={itemPath}
              strict={false}
              component={() => (
                <BenderPagesConsumer>
                  {({ pagesLoadedContent, featuredAuthors, updatePageLikes }) => {
                    return pagesLoadedContent ? (
                      <SinglePost
                        headerOverwrites={headerOverwrites}
                        updatePageLikes={updatePageLikes}
                        featuredAuthors={featuredAuthors}
                        pagesLoadedContent={pagesLoadedContent}
                        currentPostPath={itemPath.replace('/', '')}
                        primaryColor={primaryColor}
                        form_id={form.blog_form_id}
                        form_title={form.blog_form_title}
                        thank_you_message={form.blog_thank_you_message}
                        error_message={form.blog_error_message}
                        cta_label={form.blog_cta_label}
                        custom_event_tracking_label={form.blog_form_custom_event_tracking_label}
                        utm_campaign={utmCampaign}
                        utm_content={utmContent}
                        utm_medium={utmMedium}
                        utm_source={utmSource}
                        utm_term={utmTerm}
                        gclid={gclid}
                        handleGaEvent={handleGaEvent}
                        scrollToElem={scrollToElem}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        conferenceSlug={conferenceSlug}
                        isMobile={isMobile}
                        isTablet={isTablet}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        if (item.template === 'Blog') {
          return (
            <Route
              exact
              key={itemPath}
              path={`/${itemPath}`}
              component={() => (
                <BenderPagesConsumer>
                  {({
                    pagesLoadedContent,
                    addMorePages,
                    loadingMorePosts,
                    latestPosts,
                    featuredPosts,
                    currentPage,
                    currentPageNum,
                    maxPageNum
                  }) => {
                    const pageContent = pagesLoadedContent.blog || pagesLoadedContent['blog/']
                    return pageContent ? (
                      <Blog
                        pageContent={pageContent}
                        ads={ads}
                        headerOverwrites={headerOverwrites}
                        currentPagePath={item.path}
                        pagesLoadedContent={pagesLoadedContent}
                        addMorePages={addMorePages}
                        latestPosts={latestPosts}
                        featuredPosts={featuredPosts}
                        maxPageNum={maxPageNum}
                        currentPageNum={currentPageNum}
                        currentPage={currentPage === item.path}
                        loadingMorePosts={loadingMorePosts}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        primaryColor={primaryColor}
                        disableForms={disableForms}
                        disableFormsMessage={disableFormsMessage}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        handleGaEvent={handleGaEvent}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        if (item.template === 'Bender') {
          const routePath = `/${itemPath}`
          const contentPath = itemPath

          return (
            <Route
              exact
              key={itemPath}
              path={routePath}
              component={() => (
                <BenderPagesConsumer>
                  {({ currentPage, pagesLoadedContent }) => {
                    const pageContent = pagesLoadedContent[currentPage]
                    return pageContent ? (
                      <Bender
                        headerOverwrites={headerOverwrites}
                        scrollToElem={scrollToElem}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        currentPage={currentPage === contentPath}
                        pageContent={pageContent}
                        conferenceName={conferenceName}
                        conferenceSlug={conferenceSlug}
                        displayTicker={displayTicker}
                        disableForms={disableForms}
                        disableFormsMessage={disableFormsMessage}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        utm_campaign={utmCampaign}
                        utm_content={utmContent}
                        utm_medium={utmMedium}
                        utm_source={utmSource}
                        utm_term={utmTerm}
                        gclid={gclid}
                        handleGaEvent={handleGaEvent}
                        industry={industry}
                        urlParameters={urlParameters}
                        stream_token={stream_token}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        if (item.template === 'Author') {
          return (
            <Route
              exact
              path={itemPath}
              key={itemPath}
              component={() => (
                <Author
                  headerOverwrites={headerOverwrites}
                  currentPath={itemPath}
                  authorId={item.id}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  handleGaEvent={handleGaEvent}
                />
              )}
            />
          )
        }
        if (item.template === 'Category') {
          return (
            <Route
              exact
              path={itemPath}
              key={itemPath}
              component={() => (
                <BenderPagesConsumer>
                  {({
                    pagesLoadedContent,
                    getCategData,
                    getMoreCategData,
                    loadingMorePosts,
                    currentCategPageNum,
                    morePosts
                  }) => {
                    const pageContent = pagesLoadedContent[itemPath.replace('/', '')]
                    return pageContent ? (
                      <Category
                        currentPath={itemPath}
                        categSlug={item.slug}
                        {...{
                          getCategData,
                          pageContent,
                          pagesLoadedContent,
                          currentCategPageNum,
                          getMoreCategData,
                          loadingMorePosts,
                          morePosts,
                          headerOverwrites,
                          isMobile,
                          isTablet,
                          slugifiedPath,
                          slugify
                        }}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        if (item.template === 'PressRelease') {
          return (
            <Route
              exact
              path={itemPath}
              key={itemPath}
              component={() => (
                <BenderPagesConsumer>
                  {({ currentPage, pagesLoadedContent }) => {
                    const pageContent = pagesLoadedContent[currentPage]
                    return pageContent ? (
                      <SinglePressRelease
                        headerOverwrites={headerOverwrites}
                        confName={conferenceName}
                        postContent={pageContent.postData}
                        scrollToElem={scrollToElem}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        handleGaEvent={handleGaEvent}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        if (item.template === 'Essentials') {
          const esItemPath = `/essentials/:itemPath${currentPageHasTrailingSlash ? '/' : ''}`

          return (
            <Route
              path={esItemPath}
              key={item.path}
              component={e => (
                <BenderPagesConsumer>
                  {({ currentPage, pagesLoadedContent }) => {
                    const pageContent = pagesLoadedContent[currentPage]
                    return pageContent ? (
                      <Essentials
                        scrollToElem={scrollToElem}
                        headerOverwrites={headerOverwrites}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        currentPage={currentPage === item.path}
                        currentPagePath={currentPage}
                        currentItemPath={`${e.match.params.itemPath}${
                          currentPageHasTrailingSlash ? '/' : ''
                        }`}
                        pageContent={pageContent}
                        conferenceName={conferenceName}
                        conferenceSlug={conferenceSlug}
                        displayTicker={displayTicker}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        handleGaEvent={handleGaEvent}
                        urlParameters={urlParameters}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />
          )
        }
        return null
      })
    // END Loop through dynamic routes

    // return app wrap & Header
    return (
      <BenderPagesProvider>
        {pages && dynamicRoutesComponents ? (
          // switch between routes
          <Switch key="SwitchWithSlide_key">
            <Route
              exact
              path="/"
              component={() => (
                <BenderPagesConsumer>
                  {({ currentPage, pagesLoadedContent }) => {
                    const pageContent = pagesLoadedContent[currentPage]
                    return pageContent ? (
                      <Bender
                        currentPage={currentPage === '+'}
                        scrollToElem={scrollToElem}
                        headerOverwrites={headerOverwrites}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        pageContent={pageContent}
                        conferenceName={conferenceName}
                        conferenceSlug={conferenceSlug}
                        displayTicker={displayTicker}
                        disableForms={disableForms}
                        disableFormsMessage={disableFormsMessage}
                        slugifiedPath={slugifiedPath}
                        slugify={slugify}
                        utm_campaign={utmCampaign}
                        utm_content={utmContent}
                        utm_medium={utmMedium}
                        utm_source={utmSource}
                        utm_term={utmTerm}
                        gclid={gclid}
                        handleGaEvent={handleGaEvent}
                        industry={industry}
                        urlParameters={urlParameters}
                      />
                    ) : (
                      <Loader loaderType="pageLoader" primaryColor={primaryColor} />
                    )
                  }}
                </BenderPagesConsumer>
              )}
            />

            {pages && dynamicRoutesComponents}

            <Route component={() => <NotFound {...{ headerOverwrites }} />} />
          </Switch>
        ) : (
          <Loader loaderType="pageLoader" primaryColor={primaryColor} />
        )}
      </BenderPagesProvider>
    )
  }

  function tickerSetup() {
    // Ticker setup
    const pageOverwrites = header.page_overwrites
    const displayPageTicker = pageOverwrites && pageOverwrites.display_page_countdown_ticker
    const pageTickerDate = pageOverwrites && pageOverwrites.countdown_ticker
    const pageTickerTime = pageOverwrites && pageOverwrites.endtime
    const pageTickerPreText = pageOverwrites && pageOverwrites.ticker_pretext
    const pageTickerButtonText = pageOverwrites && pageOverwrites.ticker_button_text
    const pageTickerButtonUrl = pageOverwrites && pageOverwrites.ticker_button_url
    const pageTickerButtonTarget = pageOverwrites && pageOverwrites.open_link_in_new_tab
    const appTickerDate = ticker && ticker.tickerDate
    const appTickerTime = ticker && ticker.tickerTime
    const appTickerText = ticker && ticker.tickerPreText
    const appTickerButtonText = ticker && ticker.tickerButtonText
    const appTickerButtonUrl = ticker && ticker.tickerButtonUrl
    const appTickerButtonTarget = ticker && ticker.tickerButtonTarge
    const tickerDate = displayPageTicker ? pageTickerDate : appTickerDate
    const tickerTime = displayPageTicker ? pageTickerTime : appTickerTime
    const tickerText = displayPageTicker ? pageTickerPreText : appTickerText
    const tickerButtonText = displayPageTicker ? pageTickerButtonText : appTickerButtonText
    const tickerButtonUrl = displayPageTicker ? pageTickerButtonUrl : appTickerButtonUrl
    const tickerButtonTarget = displayPageTicker ? pageTickerButtonTarget : appTickerButtonTarget

    return (
      <Ticker
        {...{
          tickerText,
          tickerTime,
          tickerDate,
          tickerButtonText,
          tickerButtonTarget,
          tickerButtonUrl
        }}
      />
    )
  }

  return loadedApp ? (
    <Suspense fallback={<Loader loaderType="pageLoader" primaryColor={primaryColor} />}>
      <div className={`app ${conferenceName}`}>
        <Header key="header" {...{ scrollToElem, isMobile, isTablet, handleGaEvent }} />
        {/* Build routes */}
        {buildRoutes()}
        {/* END Build routes */}
        {/* Footer stuff and things here */}
        {footer && (
          <Footer
            key="footer"
            displayTicker={ticker && ticker.displayTicker}
            isMobile={isMobile}
            isTablet={isTablet}
            footerCircleColor={footer.footer_circle_color}
            footerFormId={
              footer.custom_footer_form ? footer.custom_footer_form_id : footer.footer_form_id
            }
            footerFormThankYouMessage={
              footer.custom_footer_form_thank_you_message
                ? footer.custom_footer_form_thank_you_message
                : footer.footer_form_thank_you_message
            }
            hideFooterForm={footer.hide_footer_form}
            customFooterForm={footer.custom_footer_form}
            footerMenu={footer.footer_menu}
            footerMenutitle1={footer.footer_menu_name_1}
            footerMenu1={footer.footer_menu_1}
            footerMenutitle2={footer.footer_menu_name_2}
            footerMenu2={footer.footer_menu_2}
            footerMenutitle3={footer.footer_menu_name_3}
            footerMenu3={footer.footer_menu_3}
            footerMenutitle4={footer.footer_menu_name_4}
            footerMenu4={footer.footer_menu_4}
            footerCopyright={footer.footer_copyright_text}
            confName={conferenceName}
            disableForms={disableForms}
            disableFormsMessage={disableFormsMessage}
            handleGaEvent={handleGaEvent}
          />
        )}
        {/* END Footer stuff and things here */}

        {/* Cookie stuff and things */}
        {navigator && navigator.cookieEnabled && cookie && <CivicCookie bannerStyle={conferenceName} />}
        {/* END Cookie stuff and things */}

        {displayTickerPopup && tickerSetup()}
      </div>
    </Suspense>
  ) : (
    <Loader loaderType="pageLoader" primaryColor={primaryColor} />
  )
}

export default App
