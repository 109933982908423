import Analytics from 'analytics'
import matomoPlugin from './Matomo'
import matomoTagMangerPlugin from './MatomoTagManager'

let domain = window.location.hostname
// check for local or staging environment
if (
  window.location.toString().indexOf('localhost') !== -1 ||
  window.location.toString().indexOf('lvh') !== -1 ||
  window.location.toString().indexOf('newsummit') !== -1 ||
  window.location.toString().indexOf('wpengine') !== -1 ||
  window.location.toString().indexOf('vercel') !== -1
) {
  domain = 'summitengine.com'
}

// Websummit container id
let container = '3ZZxaOZo'
let siteId = '11'
let url = `https://tracking.${domain}/`

if (domain.includes('collision')) {
  // Collison container id
  container = '1LOKXnO7'
  siteId = '2'
} else if (domain.includes('rise')) {
  // Rise container id
  container = 'aKnpnWK0'
  siteId = '3'
} else if (
  domain.includes('rio.websummit') ||
  (domain.includes('websummit') && window.location.pathname.includes('/rio/'))
) {
  container = 'amXzYPo8'
  siteId = '9'
  url = 'https://tracking.websummit.com/'
} else {
  container = '7nF6JbVD'
  siteId = '1'
}

const trackTagManager = {
  plugins: {
    matomo: false, // <-- Disable matomo but call all others
    matomoTagManager: true
  }
}
const trackMatomo = {
  plugins: {
    matomo: true, // <-- Enable tracking calls for matomo
    matomoTagManager: false
  }
}

const matomoTagManager = matomoTagMangerPlugin({
  enabled: true,
  url,
  container
})

const matomo = matomoPlugin({
  enabled: true,
  url,
  siteId
})

const masterAnalytics = Analytics({
  app: 'blueprint',
  plugins: [matomo, matomoTagManager]
})

export const handleAnalyticsEvent = (action, category, label) => {
  masterAnalytics.track(
    'trackEvent',
    {
      action: action || 'defaultAction',
      category: category || 'defaultCategory',
      label
    },
    trackMatomo
  )
  handleTagManagerEvent(action, category, label)
}

export const handleTagManagerEvent = (action, category, label) => {
  masterAnalytics.track(
    action || 'defaultAction',
    {
      action: action || 'defaultAction',
      category: category || 'defaultCategory',
      label,
      location: category
    },
    trackTagManager
  )
}

export const handleAnalyticsGoal = goalId => {
  window._paq.push(['trackGoal', goalId])
}

export default masterAnalytics
