import React from 'react'

import './loader.css'

const Loader = props =>
  props.loaderType === 'pageLoader' ? (
    <div className={`pageLoader${props.bgColor ? ' ' + props.bgColor + '-bg' : ''}`}>
      <div className="ball-triangle-path">
        <div style={{ backgroundColor: `${props.primaryColor}` }} />
        <div style={{ backgroundColor: `${props.primaryColor}` }} />
        <div style={{ backgroundColor: `${props.primaryColor}` }} />
      </div>
    </div>
  ) : (
    <div className={`loader${props.bgColor ? ' ' + props.bgColor + '-bg' : ''}`}>
      <div className="ball-triangle-path">
        <div />
        <div />
        <div />
      </div>
    </div>
  )

export default Loader
