/* eslint-disable */
export const _mtm = (window._mtm = window._mtm || [])

export default function matomoTagMangerPlugin({ enabled, url, container }) {
  return {
    name: 'matomoTagManager',
    config: {
      url,
      container,
      enabled
    },
    initialize: ({ config }) => {
      if (config.enabled) {
        _mtm.push({
          'mtm.startTime': new Date().getTime(),
          event: 'mtm.Start',
        });
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.type = 'text/javascript';
        g.async = true;
        g.id = 'mtm-script';
        g.src = `${config.url}js/container_${config.container}.js`;
        if (s.parentNode) {
          s.parentNode.insertBefore(g, s);
        }
      }
    },
    page: ({ payload }) => {
      _mtm.push({ event: 'mtm.PageView', ...payload.properties, url: payload.properties.url.replace('https://rise2017stg.wpengine.com', 'https://rio.websummit.com') });
    },
    track: ({ payload }) => {
      _mtm.push({ event: payload.event, ...payload.properties });
    },
  };
}