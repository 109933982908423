import Analytics from 'analytics'

const analytics = Analytics({
  app: 'bender',
  debug: true,
  plugins: []
})

/* export the instance for usage in your app */
export default analytics
